import React from "react";
import { Ribbon, RibbonGroup, RibbonGroupItem, RibbonButton } from "react-bootstrap-ribbon";

import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-ribbon/dist/react-bootstrap-ribbon.css";

import './ribbon.css'
import '../pages/Form.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { i18ndb } from "../services";

const buildOptions = (options) => {
    let items = [];
    let colClass = "col-" + Math.round(12/Object.entries(options).length)
    Object.entries(options).forEach(([option, descriptor]) => {
        items.push(
        <RibbonGroupItem colClass={colClass} key={option}>
            <RibbonButton onClick= {descriptor.do} >
                <div>{i18ndb._(descriptor.label)}</div>
            </RibbonButton>
        </RibbonGroupItem>
        )  
    });
    
    return items;
}

const buildGroups = (subMenu) => {
    let items = [];
	let colClass = "col-4"
    const subMenuKeys = Object.keys(subMenu);
	if (subMenuKeys.length > 3)
        colClass = "col-" + Math.round(12/subMenuKeys.length);
    subMenuKeys.forEach((key, index) => {
        let options = buildOptions(subMenu[key].options)
        items.push(
            <RibbonGroup title={subMenu[key].label} key={key} colClass={colClass}>
                {options}
            </RibbonGroup>
        )
        
    });
    return items;
}

export const RibbonComponent = (props) => {
    const groups = buildGroups(props.subMenu)
    return (
        <Ribbon breakpoint="lg">
            {groups}
        </Ribbon>
    )
}
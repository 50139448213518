import { store } from '../store';

/* Navigation */
export const PUSH = 'ROUTER/PUSH';
export const REPLACE = 'ROUTER/REPLACE';
export const GO = 'ROUTER/GO';
export const GO_BACK = 'ROUTER/GO_BACK';
export const GO_FORWARD = 'ROUTER/GO_FORWARD';
export const LOCATION_CHANGE = 'ROUTER/LOCATION_CHANGE';

export const push = href => ({
	type: PUSH,
	payload: href
});

export const replace = href => ({
	type: REPLACE,
	payload: href
});

export const go = index => ({
	type: GO,
	payload: index
});

export const goBack = () => ({
	type: GO_BACK
});

export const goForward = () => ({
	type: GO_FORWARD
});

export const locationChange = (location) => ({
	type: LOCATION_CHANGE,
	payload: location
});

export const pageReducer = (state = {}, action) => {
	if (action.type == action.type == PUSH) {
		if (action.payload)
			return { href: action.payload.href };
	}
	if (action.type == LOCATION_CHANGE) {
		if (action.payload)
			return { ...state, location: action.payload.location };
	}
	return state;
}

export const routerMiddleware = history => () => next => (action) => {
  switch (action.type) {
    case PUSH:
      history.push(action.payload);
      break;
    case REPLACE:
      history.replace(action.payload);
      break;
    case GO:
      history.go(action.payload);
      break;
    case GO_BACK:
      history.goBack();
      break;
    case GO_FORWARD:
      history.goForward();
      break;
    default:
      return next(action);
  }
};

export function startListener(history, store) {
	store.dispatch(locationChange(history.location));

	history.listen((location) => {
		store.dispatch(locationChange(location));
	});
}

export const moveTo = (href) => {
		store.dispatch(push(href));	
}
import { combineReducers, applyMiddleware, createStore } from 'redux'
import { createBrowserHistory } from 'history';

import { routerMiddleware, startListener, pageReducer } from './redux/BrowserNavigation'
import { guiReducer } from './events/Gui';

// Create the history object
export const history = createBrowserHistory()

// Add the reducer, which adds location state to the store
const rootReducer = combineReducers({
  gui: guiReducer,
  router: pageReducer // Convention is to use the "router" property
})
  
// Build the middleware, which intercepts navigation actions and calls the corresponding history method
const middleware = routerMiddleware(history)

// Create the store
export const store = createStore(rootReducer, {}, applyMiddleware(middleware))

// Start the history listener, which automatically dispatches actions to keep the store in sync with the history
startListener(history, store)

import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import AvatarGroup from '@mui/material/AvatarGroup';
import { getUsersWorkingOnDocument } from '../users/Collaboration';
import { DocumentInfo } from '../editors/DocumentsManagement';
import { UserInfo } from '../../../auto/js/users/UserInfo';
import { UserAvatar } from '../../../auto/js/users/Avatars';
import './titlebar.css'

type closeFunc = () => void;
type titleFunc = () => string;
type EditorTitleBarProps = { uuid: string, document?: DocumentInfo, onClose?: closeFunc };

function AvatarsCollaborating(document: DocumentInfo) {
	let [users, setUsers] = useState<UserInfo[]>([]);
	useEffect(() => {
			getUsersWorkingOnDocument(document).then((collaborating: UserInfo[]) => setUsers(collaborating));
		}, []); // change to get the focused document
		if (users.length==0) 
			return null; 	
		else if (users.length==1)
			return UserAvatar(users[0]);
		else
		return (
			    <AvatarGroup max={4}>
					{ users.map(user => UserAvatar(user)) }
			    </AvatarGroup>
		);	
}

export function EditorTitleBar(props: EditorTitleBarProps) {
	let [title, setTitle] = useState<string | titleFunc>('');
	useEffect(
		() => {window.tennex.wm.views.get(props.uuid).setTitle = setTitle},
		[]
	);
	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar position="static">
				<Toolbar>
					<IconButton
						size="large"
						edge="start"
						color="inherit"
						aria-label="menu"
					>
						<MenuIcon />
					</IconButton>
					<Typography component="div" className="fitToContent">
						{(typeof title === "function") ? title() : title}
					</Typography>
					<Typography component="div" className="drag-handle">&nbsp;</Typography>
					{props.document ? <AvatarsCollaborating {...props.document} /> : null}
					<IconButton
						size="large"
						edge="start"
						color="inherit"
						aria-label="menu"
						onClick={props.onClose}
					>
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
		</Box>
	);
}
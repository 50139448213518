import Noty from "noty";
import 'noty/lib/noty.css';
import 'noty/lib/themes/relax.css';

export const showNotification = (text, type) => {
	new Noty({
		text,
		type: type,
		theme: 'relax',
		timeout: 3000,
	}).show();
}

export function isBlank(str) {
    return (!str || /^\s*$/.test(str));
}
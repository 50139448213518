import React, {useState} from 'react';
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import { RibbonComponent } from './RibbonComponent';
import { menu } from '../../../main/js/router/Menu';
import { i18ndb } from '../services';

const buildMenu = () => {
  const items = []
  const options = menu();
  const optionsKeys = Object.keys(options);
  optionsKeys.forEach((key, index) => {
    items.push(
      <Tab eventKey={key} title={i18ndb._(options[key].label)} key={key}>
        <RibbonComponent subMenu={options[key]["submenu"]} />
      </Tab>
    )
  });
	return items;
}

export function RibbonTab() {
    const [key, setKey] = useState(Object.keys(menu)[0]);

    const tabMenu = buildMenu();
    return (
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
      >
		{tabMenu}
      </Tabs>
    );
}
  
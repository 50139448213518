import React from "react";
import { AlertDialog } from '../widgets';

export class ImplementMe extends React.Component {
	constructor(props) {
		super(props);
		this.uuid = self.crypto.randomUUID();		
	}
	
	render() {
	    return (
	        <AlertDialog
	        title="Implement me!"
	        open={true}
	        handleClose={()=>{}}
	        noAgree={true}
	    />
	    )
	}
}

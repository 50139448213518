import React from 'react';

import { loadingIcon } from "../loadingIcon";

import { ImageViewer} from './ImageViewer'


const createImage = (avatarId) => {
  if(avatarId != null)
    return({x: 0.5, y:0.5, scale: 1, rotate: 0, url: '/country-metadata-avatar/image/' + avatarId});
  else
    return({x: 0.5, y:0.5, scale: 0.5, rotate: 0, url: '/public/mission.png'})
}


export class CountryImageViewer extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        image: undefined,
      };
      if(props.countryObservable)
        props.countryObservable.subscribe(this);
    }
    process = (avatarId) => {
      this.setState({image: createImage(avatarId)})
    }
    render = () => {
      if (!this.state.image) {
        this.setState({image: createImage(this.props.avatarId)});
        return loadingIcon;
      }
      return (
        <ImageViewer width={240} height={160} borderRadiusStyle={'0px'} image={this.state.image}/>
      );
    };
  }
export class Observable {
    constructor() {
        this.callbacks = [];
    }

    subscribe( callback ) {
        this.callbacks.push( callback );
    }

    unsubscribe( c ) {
        this.callbacks = this.callbacks.filter( callback => callback !== c );
    }

    publish( event ) {
        this.callbacks.forEach( callback => callback( event ) );
    }
}

export const pojoMetadata = {
			'protocol-mapper': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "clientId", name: "client_id", editable: true, resizable: true, type: "text" },
			   	{ key: "clientScopeId", name: "client_scope_id", editable: true, resizable: true, type: "text" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "protocol", name: "protocol", editable: true, resizable: true, type: "text" },
			   	{ key: "protocolMapperName", name: "protocol_mapper_name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.clientId == "")
					data.clientId = null;
				if(data.clientScopeId == "")
					data.clientScopeId = null;
				if(data.name == "")
					data.name = null;
				if(data.protocol == "")
					data.protocol = null;
				if(data.protocolMapperName == "")
					data.protocolMapperName = null;
				return data
			}
		},
		'user-consent': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "clientId", name: "client_id", editable: true, resizable: true, type: "text" },
			   	{ key: "clientStorageProvider", name: "client_storage_provider", editable: true, resizable: true, type: "text" },
			   	{ key: "createdDate", name: "created_date", editable: true, resizable: true, type: "number" },
			   	{ key: "externalClientId", name: "external_client_id", editable: true, resizable: true, type: "text" },
			   	{ key: "lastUpdatedDate", name: "last_updated_date", editable: true, resizable: true, type: "number" },
			   	{ key: "userId", name: "user_id", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.clientId == "")
					data.clientId = null;
				if(data.clientStorageProvider == "")
					data.clientStorageProvider = null;
				if(data.externalClientId == "")
					data.externalClientId = null;
				if(data.userId == "")
					data.userId = null;
				return data
			}
		},
		'user-entity': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "createdTimestamp", name: "created_timestamp", editable: true, resizable: true, type: "number" },
			   	{ key: "email", name: "email", editable: true, resizable: true, type: "text" },
			   	{ key: "emailConstraint", name: "email_constraint", editable: true, resizable: true, type: "text" },
			   	{ key: "emailVerified", name: "email_verified", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "enabled", name: "enabled", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "federationLink", name: "federation_link", editable: true, resizable: true, type: "text" },
			   	{ key: "firstName", name: "first_name", editable: true, resizable: true, type: "text" },
			   	{ key: "lastName", name: "last_name", editable: true, resizable: true, type: "text" },
			   	{ key: "notBefore", name: "not_before", editable: true, resizable: true, type: "number" },
			   	{ key: "realmId", name: "realm_id", editable: true, resizable: true, type: "text" },
			   	{ key: "serviceAccountClientLink", name: "service_account_client_link", editable: true, resizable: true, type: "text" },
			   	{ key: "touched", name: "touched", editable: true, resizable: true, type: "text" },
			   	{ key: "username", name: "username", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.email == "")
					data.email = null;
				if(data.emailConstraint == "")
					data.emailConstraint = null;
				if(data.federationLink == "")
					data.federationLink = null;
				if(data.firstName == "")
					data.firstName = null;
				if(data.lastName == "")
					data.lastName = null;
				if(data.realmId == "")
					data.realmId = null;
				if(data.serviceAccountClientLink == "")
					data.serviceAccountClientLink = null;
				if(data.username == "")
					data.username = null;
				return data
			}
		},
		'resource-server-resource': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "displayName", name: "display_name", editable: true, resizable: true, type: "text" },
			   	{ key: "iconUri", name: "icon_uri", editable: true, resizable: true, type: "text" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "owner", name: "owner", editable: true, resizable: true, type: "text" },
			   	{ key: "ownerManagedAccess", name: "owner_managed_access", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "resourceServerId", name: "resource_server_id", editable: true, resizable: true, type: "text" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.displayName == "")
					data.displayName = null;
				if(data.iconUri == "")
					data.iconUri = null;
				if(data.name == "")
					data.name = null;
				if(data.owner == "")
					data.owner = null;
				if(data.resourceServerId == "")
					data.resourceServerId = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'resource-server': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "allowRsRemoteMgmt", name: "allow_rs_remote_mgmt", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "decisionStrategy", name: "decision_strategy", editable: true, resizable: true, type: "number" },
			   	{ key: "policyEnforceMode", name: "policy_enforce_mode", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.policyEnforceMode == "")
					data.policyEnforceMode = null;
				return data
			}
		},
		'user-attribute': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "userId", name: "user_id", editable: true, resizable: true, type: "text" },
			   	{ key: "value", name: "value", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				if(data.userId == "")
					data.userId = null;
				if(data.value == "")
					data.value = null;
				return data
			}
		},
		'admin-event-entity': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "adminEventTime", name: "admin_event_time", editable: true, resizable: true, type: "number" },
			   	{ key: "authClientId", name: "auth_client_id", editable: true, resizable: true, type: "text" },
			   	{ key: "authRealmId", name: "auth_realm_id", editable: true, resizable: true, type: "text" },
			   	{ key: "authUserId", name: "auth_user_id", editable: true, resizable: true, type: "text" },
			   	{ key: "error", name: "error", editable: true, resizable: true, type: "text" },
			   	{ key: "ipAddress", name: "ip_address", editable: true, resizable: true, type: "text" },
			   	{ key: "operationType", name: "operation_type", editable: true, resizable: true, type: "text" },
			   	{ key: "realmId", name: "realm_id", editable: true, resizable: true, type: "text" },
			   	{ key: "representation", name: "representation", editable: true, resizable: true, type: "text" },
			   	{ key: "resourcePath", name: "resource_path", editable: true, resizable: true, type: "text" },
			   	{ key: "resourceType", name: "resource_type", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.authClientId == "")
					data.authClientId = null;
				if(data.authRealmId == "")
					data.authRealmId = null;
				if(data.authUserId == "")
					data.authUserId = null;
				if(data.error == "")
					data.error = null;
				if(data.ipAddress == "")
					data.ipAddress = null;
				if(data.operationType == "")
					data.operationType = null;
				if(data.realmId == "")
					data.realmId = null;
				if(data.representation == "")
					data.representation = null;
				if(data.resourcePath == "")
					data.resourcePath = null;
				if(data.resourceType == "")
					data.resourceType = null;
				return data
			}
		},
		'credential': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "createdDate", name: "created_date", editable: true, resizable: true, type: "number" },
			   	{ key: "credentialData", name: "credential_data", editable: true, resizable: true, type: "text" },
			   	{ key: "priority", name: "priority", editable: true, resizable: true, type: "number" },
			   	{ key: "salt", name: "salt", editable: true, resizable: true, type: "blob" },
			   	{ key: "secretData", name: "secret_data", editable: true, resizable: true, type: "text" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			   	{ key: "userId", name: "user_id", editable: true, resizable: true, type: "text" },
			   	{ key: "userLabel", name: "user_label", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.credentialData == "")
					data.credentialData = null;
				if(data.secretData == "")
					data.secretData = null;
				if(data.type == "")
					data.type = null;
				if(data.userId == "")
					data.userId = null;
				if(data.userLabel == "")
					data.userLabel = null;
				return data
			}
		},
		'permissions': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'fed-user-attribute': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "realmId", name: "realm_id", editable: true, resizable: true, type: "text" },
			   	{ key: "storageProviderId", name: "storage_provider_id", editable: true, resizable: true, type: "text" },
			   	{ key: "userId", name: "user_id", editable: true, resizable: true, type: "text" },
			   	{ key: "value", name: "value", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				if(data.realmId == "")
					data.realmId = null;
				if(data.storageProviderId == "")
					data.storageProviderId = null;
				if(data.userId == "")
					data.userId = null;
				if(data.value == "")
					data.value = null;
				return data
			}
		},
		'databasechangeloglock': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "locked", name: "locked", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "lockedby", name: "lockedby", editable: true, resizable: true, type: "text" },
			   	{ key: "lockgranted", name: "lockgranted", editable: true, resizable: true, type: "timestampz" },
			],
			form2dto: (data) => {
				if(data.lockedby == "")
					data.lockedby = null;
				if(data.lockgranted != null && data.lockgranted instanceof Date)
					data.lockgranted = data.lockgranted.getFullYear() + "-" + ('0' + (data.lockgranted.getMonth() + 1)).slice(-2) + "-" + ('0' + data.lockgranted.getDate()).slice(-2);
				return data
			}
		},
		'user-preferences': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "key", name: "key", editable: true, resizable: true, type: "text" },
			   	{ key: "userId", name: "user_id", editable: true, resizable: true, type: "text" },
			   	{ key: "value", name: "value", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.key == "")
					data.key = null;
				if(data.userId == "")
					data.userId = null;
				if(data.value == "")
					data.value = null;
				return data
			}
		},
		'identity-provider-mapper': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "idpAlias", name: "idp_alias", editable: true, resizable: true, type: "text" },
			   	{ key: "idpMapperName", name: "idp_mapper_name", editable: true, resizable: true, type: "text" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "realmId", name: "realm_id", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.idpAlias == "")
					data.idpAlias = null;
				if(data.idpMapperName == "")
					data.idpMapperName = null;
				if(data.name == "")
					data.name = null;
				if(data.realmId == "")
					data.realmId = null;
				return data
			}
		},
		'tnx-plugin': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "major", name: "major", editable: true, resizable: true, type: "number" },
			   	{ key: "minor", name: "minor", editable: true, resizable: true, type: "number" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "patch", name: "patch", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'authenticator-config': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "alias", name: "alias", editable: true, resizable: true, type: "text" },
			   	{ key: "realmId", name: "realm_id", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.alias == "")
					data.alias = null;
				if(data.realmId == "")
					data.realmId = null;
				return data
			}
		},
		'i18n': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "domainId", name: "domain_id", editable: true, resizable: true, type: "text" },
			   	{ key: "localeId", name: "locale_id", editable: true, resizable: true, type: "text" },
			   	{ key: "messageId", name: "message_id", editable: true, resizable: true, type: "text" },
			   	{ key: "value", name: "value", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.domainId == "")
					data.domainId = null;
				if(data.localeId == "")
					data.localeId = null;
				if(data.messageId == "")
					data.messageId = null;
				if(data.value == "")
					data.value = null;
				return data
			}
		},
		'authentication-flow': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "alias", name: "alias", editable: true, resizable: true, type: "text" },
			   	{ key: "builtIn", name: "built_in", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "description", name: "description", editable: true, resizable: true, type: "text" },
			   	{ key: "providerId", name: "provider_id", editable: true, resizable: true, type: "text" },
			   	{ key: "realmId", name: "realm_id", editable: true, resizable: true, type: "text" },
			   	{ key: "topLevel", name: "top_level", editable: true, resizable: true, type: "checkbox" },
			],
			form2dto: (data) => {
				if(data.alias == "")
					data.alias = null;
				if(data.description == "")
					data.description = null;
				if(data.providerId == "")
					data.providerId = null;
				if(data.realmId == "")
					data.realmId = null;
				return data
			}
		},
		'authentication-execution': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "alias", name: "alias", editable: true, resizable: true, type: "text" },
			   	{ key: "authConfig", name: "auth_config", editable: true, resizable: true, type: "text" },
			   	{ key: "authFlowId", name: "auth_flow_id", editable: true, resizable: true, type: "text" },
			   	{ key: "authenticator", name: "authenticator", editable: true, resizable: true, type: "text" },
			   	{ key: "authenticatorFlow", name: "authenticator_flow", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "flowId", name: "flow_id", editable: true, resizable: true, type: "text" },
			   	{ key: "priority", name: "priority", editable: true, resizable: true, type: "number" },
			   	{ key: "realmId", name: "realm_id", editable: true, resizable: true, type: "text" },
			   	{ key: "requirement", name: "requirement", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.alias == "")
					data.alias = null;
				if(data.authConfig == "")
					data.authConfig = null;
				if(data.authFlowId == "")
					data.authFlowId = null;
				if(data.authenticator == "")
					data.authenticator = null;
				if(data.flowId == "")
					data.flowId = null;
				if(data.realmId == "")
					data.realmId = null;
				return data
			}
		},
		'client-session': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "authMethod", name: "auth_method", editable: true, resizable: true, type: "text" },
			   	{ key: "authUserId", name: "auth_user_id", editable: true, resizable: true, type: "text" },
			   	{ key: "clientId", name: "client_id", editable: true, resizable: true, type: "text" },
			   	{ key: "currentAction", name: "current_action", editable: true, resizable: true, type: "text" },
			   	{ key: "realmId", name: "realm_id", editable: true, resizable: true, type: "text" },
			   	{ key: "redirectUri", name: "redirect_uri", editable: true, resizable: true, type: "text" },
			   	{ key: "sessionId", name: "session_id", editable: true, resizable: true, type: "text" },
			   	{ key: "state", name: "state", editable: true, resizable: true, type: "text" },
			   	{ key: "timestamp", name: "timestamp", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.authMethod == "")
					data.authMethod = null;
				if(data.authUserId == "")
					data.authUserId = null;
				if(data.clientId == "")
					data.clientId = null;
				if(data.currentAction == "")
					data.currentAction = null;
				if(data.realmId == "")
					data.realmId = null;
				if(data.redirectUri == "")
					data.redirectUri = null;
				if(data.sessionId == "")
					data.sessionId = null;
				if(data.state == "")
					data.state = null;
				return data
			}
		},
		'fed-user-consent': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "clientId", name: "client_id", editable: true, resizable: true, type: "text" },
			   	{ key: "clientStorageProvider", name: "client_storage_provider", editable: true, resizable: true, type: "text" },
			   	{ key: "createdDate", name: "created_date", editable: true, resizable: true, type: "number" },
			   	{ key: "externalClientId", name: "external_client_id", editable: true, resizable: true, type: "text" },
			   	{ key: "lastUpdatedDate", name: "last_updated_date", editable: true, resizable: true, type: "number" },
			   	{ key: "realmId", name: "realm_id", editable: true, resizable: true, type: "text" },
			   	{ key: "storageProviderId", name: "storage_provider_id", editable: true, resizable: true, type: "text" },
			   	{ key: "userId", name: "user_id", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.clientId == "")
					data.clientId = null;
				if(data.clientStorageProvider == "")
					data.clientStorageProvider = null;
				if(data.externalClientId == "")
					data.externalClientId = null;
				if(data.realmId == "")
					data.realmId = null;
				if(data.storageProviderId == "")
					data.storageProviderId = null;
				if(data.userId == "")
					data.userId = null;
				return data
			}
		},
		'keycloak-role': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "client", name: "client", editable: true, resizable: true, type: "text" },
			   	{ key: "clientRealmConstraint", name: "client_realm_constraint", editable: true, resizable: true, type: "text" },
			   	{ key: "clientRole", name: "client_role", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "description", name: "description", editable: true, resizable: true, type: "text" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "realm", name: "realm", editable: true, resizable: true, type: "text" },
			   	{ key: "realmId", name: "realm_id", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.client == "")
					data.client = null;
				if(data.clientRealmConstraint == "")
					data.clientRealmConstraint = null;
				if(data.description == "")
					data.description = null;
				if(data.name == "")
					data.name = null;
				if(data.realm == "")
					data.realm = null;
				if(data.realmId == "")
					data.realmId = null;
				return data
			}
		},
		'user-federation-mapper': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "federationMapperType", name: "federation_mapper_type", editable: true, resizable: true, type: "text" },
			   	{ key: "federationProviderId", name: "federation_provider_id", editable: true, resizable: true, type: "text" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "realmId", name: "realm_id", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.federationMapperType == "")
					data.federationMapperType = null;
				if(data.federationProviderId == "")
					data.federationProviderId = null;
				if(data.name == "")
					data.name = null;
				if(data.realmId == "")
					data.realmId = null;
				return data
			}
		},
		'party': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				return data
			}
		},
		'fs': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "mimeType", name: "mime_type", editable: true, resizable: true, type: "text" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "owner", name: "owner", editable: true, resizable: true, type: "text" },
			   	{ key: "parentId", name: "parent_id", editable: true, resizable: true, type: "number" },
			   	{ key: "path", name: "path", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.mimeType == "")
					data.mimeType = null;
				if(data.name == "")
					data.name = null;
				if(data.owner == "")
					data.owner = null;
				if(data.path == "")
					data.path = null;
				return data
			}
		},
		'event-entity': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "clientId", name: "client_id", editable: true, resizable: true, type: "text" },
			   	{ key: "detailsJson", name: "details_json", editable: true, resizable: true, type: "text" },
			   	{ key: "error", name: "error", editable: true, resizable: true, type: "text" },
			   	{ key: "eventTime", name: "event_time", editable: true, resizable: true, type: "number" },
			   	{ key: "ipAddress", name: "ip_address", editable: true, resizable: true, type: "text" },
			   	{ key: "realmId", name: "realm_id", editable: true, resizable: true, type: "text" },
			   	{ key: "sessionId", name: "session_id", editable: true, resizable: true, type: "text" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			   	{ key: "userId", name: "user_id", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.clientId == "")
					data.clientId = null;
				if(data.detailsJson == "")
					data.detailsJson = null;
				if(data.error == "")
					data.error = null;
				if(data.ipAddress == "")
					data.ipAddress = null;
				if(data.realmId == "")
					data.realmId = null;
				if(data.sessionId == "")
					data.sessionId = null;
				if(data.type == "")
					data.type = null;
				if(data.userId == "")
					data.userId = null;
				return data
			}
		},
		'gui-plugin': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "major", name: "major", editable: true, resizable: true, type: "number" },
			   	{ key: "minor", name: "minor", editable: true, resizable: true, type: "number" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "patch", name: "patch", editable: true, resizable: true, type: "number" },
			   	{ key: "pluginPath", name: "plugin_path", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				if(data.pluginPath == "")
					data.pluginPath = null;
				return data
			}
		},
		'required-action-provider': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "alias", name: "alias", editable: true, resizable: true, type: "text" },
			   	{ key: "defaultAction", name: "default_action", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "enabled", name: "enabled", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "priority", name: "priority", editable: true, resizable: true, type: "number" },
			   	{ key: "providerId", name: "provider_id", editable: true, resizable: true, type: "text" },
			   	{ key: "realmId", name: "realm_id", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.alias == "")
					data.alias = null;
				if(data.name == "")
					data.name = null;
				if(data.providerId == "")
					data.providerId = null;
				if(data.realmId == "")
					data.realmId = null;
				return data
			}
		},
		'client': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "alwaysDisplayInConsole", name: "always_display_in_console", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "baseUrl", name: "base_url", editable: true, resizable: true, type: "text" },
			   	{ key: "bearerOnly", name: "bearer_only", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "clientAuthenticatorType", name: "client_authenticator_type", editable: true, resizable: true, type: "text" },
			   	{ key: "clientId", name: "client_id", editable: true, resizable: true, type: "text" },
			   	{ key: "consentRequired", name: "consent_required", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "description", name: "description", editable: true, resizable: true, type: "text" },
			   	{ key: "directAccessGrantsEnabled", name: "direct_access_grants_enabled", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "enabled", name: "enabled", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "frontchannelLogout", name: "frontchannel_logout", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "fullScopeAllowed", name: "full_scope_allowed", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "implicitFlowEnabled", name: "implicit_flow_enabled", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "managementUrl", name: "management_url", editable: true, resizable: true, type: "text" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "nodeReregTimeout", name: "node_rereg_timeout", editable: true, resizable: true, type: "number" },
			   	{ key: "notBefore", name: "not_before", editable: true, resizable: true, type: "number" },
			   	{ key: "protocol", name: "protocol", editable: true, resizable: true, type: "text" },
			   	{ key: "publicClient", name: "public_client", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "realmId", name: "realm_id", editable: true, resizable: true, type: "text" },
			   	{ key: "registrationToken", name: "registration_token", editable: true, resizable: true, type: "text" },
			   	{ key: "rootUrl", name: "root_url", editable: true, resizable: true, type: "text" },
			   	{ key: "secret", name: "secret", editable: true, resizable: true, type: "text" },
			   	{ key: "serviceAccountsEnabled", name: "service_accounts_enabled", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "standardFlowEnabled", name: "standard_flow_enabled", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "surrogateAuthRequired", name: "surrogate_auth_required", editable: true, resizable: true, type: "checkbox" },
			],
			form2dto: (data) => {
				if(data.baseUrl == "")
					data.baseUrl = null;
				if(data.clientAuthenticatorType == "")
					data.clientAuthenticatorType = null;
				if(data.clientId == "")
					data.clientId = null;
				if(data.description == "")
					data.description = null;
				if(data.managementUrl == "")
					data.managementUrl = null;
				if(data.name == "")
					data.name = null;
				if(data.protocol == "")
					data.protocol = null;
				if(data.realmId == "")
					data.realmId = null;
				if(data.registrationToken == "")
					data.registrationToken = null;
				if(data.rootUrl == "")
					data.rootUrl = null;
				if(data.secret == "")
					data.secret = null;
				return data
			}
		},
		'role-attribute': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "roleId", name: "role_id", editable: true, resizable: true, type: "text" },
			   	{ key: "value", name: "value", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				if(data.roleId == "")
					data.roleId = null;
				if(data.value == "")
					data.value = null;
				return data
			}
		},
		'resource-server-perm-ticket': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "createdTimestamp", name: "created_timestamp", editable: true, resizable: true, type: "number" },
			   	{ key: "grantedTimestamp", name: "granted_timestamp", editable: true, resizable: true, type: "number" },
			   	{ key: "owner", name: "owner", editable: true, resizable: true, type: "text" },
			   	{ key: "policyId", name: "policy_id", editable: true, resizable: true, type: "text" },
			   	{ key: "requester", name: "requester", editable: true, resizable: true, type: "text" },
			   	{ key: "resourceId", name: "resource_id", editable: true, resizable: true, type: "text" },
			   	{ key: "resourceServerId", name: "resource_server_id", editable: true, resizable: true, type: "text" },
			   	{ key: "scopeId", name: "scope_id", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.owner == "")
					data.owner = null;
				if(data.policyId == "")
					data.policyId = null;
				if(data.requester == "")
					data.requester = null;
				if(data.resourceId == "")
					data.resourceId = null;
				if(data.resourceServerId == "")
					data.resourceServerId = null;
				if(data.scopeId == "")
					data.scopeId = null;
				return data
			}
		},
		'organization-registration-form': {
			columns: [
			   	{ key: "email", name: "email", editable: true, resizable: true, type: "text" },
			   	{ key: "firstName", name: "first_name", editable: true, resizable: true, type: "text" },
			   	{ key: "lastName", name: "last_name", editable: true, resizable: true, type: "text" },
			   	{ key: "organizationname", name: "organizationname", editable: true, resizable: true, type: "text" },
			   	{ key: "password", name: "password", editable: true, resizable: true, type: "password" },
			   	{ key: "username", name: "username", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.email == "")
					data.email = null;
				if(data.firstName == "")
					data.firstName = null;
				if(data.lastName == "")
					data.lastName = null;
				if(data.organizationname == "")
					data.organizationname = null;
				if(data.password == "")
					data.password = null;
				if(data.username == "")
					data.username = null;
				return data
			}
		},
		'client-scope': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "description", name: "description", editable: true, resizable: true, type: "text" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "protocol", name: "protocol", editable: true, resizable: true, type: "text" },
			   	{ key: "realmId", name: "realm_id", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.description == "")
					data.description = null;
				if(data.name == "")
					data.name = null;
				if(data.protocol == "")
					data.protocol = null;
				if(data.realmId == "")
					data.realmId = null;
				return data
			}
		},
		'user-session': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "authMethod", name: "auth_method", editable: true, resizable: true, type: "text" },
			   	{ key: "brokerSessionId", name: "broker_session_id", editable: true, resizable: true, type: "text" },
			   	{ key: "brokerUserId", name: "broker_user_id", editable: true, resizable: true, type: "text" },
			   	{ key: "ipAddress", name: "ip_address", editable: true, resizable: true, type: "text" },
			   	{ key: "lastSessionRefresh", name: "last_session_refresh", editable: true, resizable: true, type: "number" },
			   	{ key: "loginUsername", name: "login_username", editable: true, resizable: true, type: "text" },
			   	{ key: "realmId", name: "realm_id", editable: true, resizable: true, type: "text" },
			   	{ key: "rememberMe", name: "remember_me", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "started", name: "started", editable: true, resizable: true, type: "number" },
			   	{ key: "userId", name: "user_id", editable: true, resizable: true, type: "text" },
			   	{ key: "userSessionState", name: "user_session_state", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.authMethod == "")
					data.authMethod = null;
				if(data.brokerSessionId == "")
					data.brokerSessionId = null;
				if(data.brokerUserId == "")
					data.brokerUserId = null;
				if(data.ipAddress == "")
					data.ipAddress = null;
				if(data.loginUsername == "")
					data.loginUsername = null;
				if(data.realmId == "")
					data.realmId = null;
				if(data.userId == "")
					data.userId = null;
				return data
			}
		},
		'federated-user': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "realmId", name: "realm_id", editable: true, resizable: true, type: "text" },
			   	{ key: "storageProviderId", name: "storage_provider_id", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.realmId == "")
					data.realmId = null;
				if(data.storageProviderId == "")
					data.storageProviderId = null;
				return data
			}
		},
		'resource-server-policy': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "decisionStrategy", name: "decision_strategy", editable: true, resizable: true, type: "text" },
			   	{ key: "description", name: "description", editable: true, resizable: true, type: "text" },
			   	{ key: "logic", name: "logic", editable: true, resizable: true, type: "text" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "owner", name: "owner", editable: true, resizable: true, type: "text" },
			   	{ key: "resourceServerId", name: "resource_server_id", editable: true, resizable: true, type: "text" },
			   	{ key: "type", name: "type", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.decisionStrategy == "")
					data.decisionStrategy = null;
				if(data.description == "")
					data.description = null;
				if(data.logic == "")
					data.logic = null;
				if(data.name == "")
					data.name = null;
				if(data.owner == "")
					data.owner = null;
				if(data.resourceServerId == "")
					data.resourceServerId = null;
				if(data.type == "")
					data.type = null;
				return data
			}
		},
		'team': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "partyId", name: "party_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'keycloak-group': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "parentGroup", name: "parent_group", editable: true, resizable: true, type: "text" },
			   	{ key: "realmId", name: "realm_id", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				if(data.parentGroup == "")
					data.parentGroup = null;
				if(data.realmId == "")
					data.realmId = null;
				return data
			}
		},
		'migration-model': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "updateTime", name: "update_time", editable: true, resizable: true, type: "number" },
			   	{ key: "version", name: "version", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.version == "")
					data.version = null;
				return data
			}
		},
		'user-registration-form': {
			columns: [
			   	{ key: "email", name: "email", editable: true, resizable: true, type: "text" },
			   	{ key: "firstName", name: "first_name", editable: true, resizable: true, type: "text" },
			   	{ key: "lastName", name: "last_name", editable: true, resizable: true, type: "text" },
			   	{ key: "password", name: "password", editable: true, resizable: true, type: "password" },
			   	{ key: "username", name: "username", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.email == "")
					data.email = null;
				if(data.firstName == "")
					data.firstName = null;
				if(data.lastName == "")
					data.lastName = null;
				if(data.password == "")
					data.password = null;
				if(data.username == "")
					data.username = null;
				return data
			}
		},
		'component': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "parentId", name: "parent_id", editable: true, resizable: true, type: "text" },
			   	{ key: "providerId", name: "provider_id", editable: true, resizable: true, type: "text" },
			   	{ key: "providerType", name: "provider_type", editable: true, resizable: true, type: "text" },
			   	{ key: "realmId", name: "realm_id", editable: true, resizable: true, type: "text" },
			   	{ key: "subType", name: "sub_type", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				if(data.parentId == "")
					data.parentId = null;
				if(data.providerId == "")
					data.providerId = null;
				if(data.providerType == "")
					data.providerType = null;
				if(data.realmId == "")
					data.realmId = null;
				if(data.subType == "")
					data.subType = null;
				return data
			}
		},
		'identity-provider': {
			columns: [
			   	{ key: "internalId", name: "internal_id", editable: false, resizable: true},
			   	{ key: "addTokenRole", name: "add_token_role", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "authenticateByDefault", name: "authenticate_by_default", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "enabled", name: "enabled", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "firstBrokerLoginFlowId", name: "first_broker_login_flow_id", editable: true, resizable: true, type: "text" },
			   	{ key: "linkOnly", name: "link_only", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "postBrokerLoginFlowId", name: "post_broker_login_flow_id", editable: true, resizable: true, type: "text" },
			   	{ key: "providerAlias", name: "provider_alias", editable: true, resizable: true, type: "text" },
			   	{ key: "providerDisplayName", name: "provider_display_name", editable: true, resizable: true, type: "text" },
			   	{ key: "providerId", name: "provider_id", editable: true, resizable: true, type: "text" },
			   	{ key: "realmId", name: "realm_id", editable: true, resizable: true, type: "text" },
			   	{ key: "storeToken", name: "store_token", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "trustEmail", name: "trust_email", editable: true, resizable: true, type: "checkbox" },
			],
			form2dto: (data) => {
				if(data.firstBrokerLoginFlowId == "")
					data.firstBrokerLoginFlowId = null;
				if(data.postBrokerLoginFlowId == "")
					data.postBrokerLoginFlowId = null;
				if(data.providerAlias == "")
					data.providerAlias = null;
				if(data.providerDisplayName == "")
					data.providerDisplayName = null;
				if(data.providerId == "")
					data.providerId = null;
				if(data.realmId == "")
					data.realmId = null;
				return data
			}
		},
		'client-initial-access': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "count", name: "count", editable: true, resizable: true, type: "number" },
			   	{ key: "expiration", name: "expiration", editable: true, resizable: true, type: "number" },
			   	{ key: "realmId", name: "realm_id", editable: true, resizable: true, type: "text" },
			   	{ key: "remainingCount", name: "remaining_count", editable: true, resizable: true, type: "number" },
			   	{ key: "timestamp", name: "timestamp", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.realmId == "")
					data.realmId = null;
				return data
			}
		},
		'component-config': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "componentId", name: "component_id", editable: true, resizable: true, type: "text" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "value", name: "value", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.componentId == "")
					data.componentId = null;
				if(data.name == "")
					data.name = null;
				if(data.value == "")
					data.value = null;
				return data
			}
		},
		'organization': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "partyId", name: "party_id", editable: true, resizable: true, type: "number" },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				return data
			}
		},
		'user-federation-provider': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "changedSyncPeriod", name: "changed_sync_period", editable: true, resizable: true, type: "number" },
			   	{ key: "displayName", name: "display_name", editable: true, resizable: true, type: "text" },
			   	{ key: "fullSyncPeriod", name: "full_sync_period", editable: true, resizable: true, type: "number" },
			   	{ key: "lastSync", name: "last_sync", editable: true, resizable: true, type: "number" },
			   	{ key: "priority", name: "priority", editable: true, resizable: true, type: "number" },
			   	{ key: "providerName", name: "provider_name", editable: true, resizable: true, type: "text" },
			   	{ key: "realmId", name: "realm_id", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.displayName == "")
					data.displayName = null;
				if(data.providerName == "")
					data.providerName = null;
				if(data.realmId == "")
					data.realmId = null;
				return data
			}
		},
		'realm': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "accessCodeLifespan", name: "access_code_lifespan", editable: true, resizable: true, type: "number" },
			   	{ key: "accessTokenLifeImplicit", name: "access_token_life_implicit", editable: true, resizable: true, type: "number" },
			   	{ key: "accessTokenLifespan", name: "access_token_lifespan", editable: true, resizable: true, type: "number" },
			   	{ key: "accountTheme", name: "account_theme", editable: true, resizable: true, type: "text" },
			   	{ key: "adminEventsDetailsEnabled", name: "admin_events_details_enabled", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "adminEventsEnabled", name: "admin_events_enabled", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "adminTheme", name: "admin_theme", editable: true, resizable: true, type: "text" },
			   	{ key: "allowUserManagedAccess", name: "allow_user_managed_access", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "browserFlow", name: "browser_flow", editable: true, resizable: true, type: "text" },
			   	{ key: "clientAuthFlow", name: "client_auth_flow", editable: true, resizable: true, type: "text" },
			   	{ key: "defaultLocale", name: "default_locale", editable: true, resizable: true, type: "text" },
			   	{ key: "defaultRole", name: "default_role", editable: true, resizable: true, type: "text" },
			   	{ key: "directGrantFlow", name: "direct_grant_flow", editable: true, resizable: true, type: "text" },
			   	{ key: "dockerAuthFlow", name: "docker_auth_flow", editable: true, resizable: true, type: "text" },
			   	{ key: "duplicateEmailsAllowed", name: "duplicate_emails_allowed", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "editUsernameAllowed", name: "edit_username_allowed", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "emailTheme", name: "email_theme", editable: true, resizable: true, type: "text" },
			   	{ key: "enabled", name: "enabled", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "eventsEnabled", name: "events_enabled", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "eventsExpiration", name: "events_expiration", editable: true, resizable: true, type: "number" },
			   	{ key: "internationalizationEnabled", name: "internationalization_enabled", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "loginLifespan", name: "login_lifespan", editable: true, resizable: true, type: "number" },
			   	{ key: "loginTheme", name: "login_theme", editable: true, resizable: true, type: "text" },
			   	{ key: "loginWithEmailAllowed", name: "login_with_email_allowed", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "masterAdminClient", name: "master_admin_client", editable: true, resizable: true, type: "text" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "notBefore", name: "not_before", editable: true, resizable: true, type: "number" },
			   	{ key: "offlineSessionIdleTimeout", name: "offline_session_idle_timeout", editable: true, resizable: true, type: "number" },
			   	{ key: "otpPolicyAlg", name: "otp_policy_alg", editable: true, resizable: true, type: "text" },
			   	{ key: "otpPolicyCounter", name: "otp_policy_counter", editable: true, resizable: true, type: "number" },
			   	{ key: "otpPolicyDigits", name: "otp_policy_digits", editable: true, resizable: true, type: "number" },
			   	{ key: "otpPolicyPeriod", name: "otp_policy_period", editable: true, resizable: true, type: "number" },
			   	{ key: "otpPolicyType", name: "otp_policy_type", editable: true, resizable: true, type: "text" },
			   	{ key: "otpPolicyWindow", name: "otp_policy_window", editable: true, resizable: true, type: "number" },
			   	{ key: "passwordPolicy", name: "password_policy", editable: true, resizable: true, type: "text" },
			   	{ key: "refreshTokenMaxReuse", name: "refresh_token_max_reuse", editable: true, resizable: true, type: "number" },
			   	{ key: "regEmailAsUsername", name: "reg_email_as_username", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "registrationAllowed", name: "registration_allowed", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "registrationFlow", name: "registration_flow", editable: true, resizable: true, type: "text" },
			   	{ key: "rememberMe", name: "remember_me", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "resetCredentialsFlow", name: "reset_credentials_flow", editable: true, resizable: true, type: "text" },
			   	{ key: "resetPasswordAllowed", name: "reset_password_allowed", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "revokeRefreshToken", name: "revoke_refresh_token", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "social", name: "social", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "sslRequired", name: "ssl_required", editable: true, resizable: true, type: "text" },
			   	{ key: "ssoIdleTimeout", name: "sso_idle_timeout", editable: true, resizable: true, type: "number" },
			   	{ key: "ssoIdleTimeoutRememberMe", name: "sso_idle_timeout_remember_me", editable: true, resizable: true, type: "number" },
			   	{ key: "ssoMaxLifespan", name: "sso_max_lifespan", editable: true, resizable: true, type: "number" },
			   	{ key: "ssoMaxLifespanRememberMe", name: "sso_max_lifespan_remember_me", editable: true, resizable: true, type: "number" },
			   	{ key: "updateProfileOnSocLogin", name: "update_profile_on_soc_login", editable: true, resizable: true, type: "checkbox" },
			   	{ key: "userActionLifespan", name: "user_action_lifespan", editable: true, resizable: true, type: "number" },
			   	{ key: "verifyEmail", name: "verify_email", editable: true, resizable: true, type: "checkbox" },
			],
			form2dto: (data) => {
				if(data.accountTheme == "")
					data.accountTheme = null;
				if(data.adminTheme == "")
					data.adminTheme = null;
				if(data.browserFlow == "")
					data.browserFlow = null;
				if(data.clientAuthFlow == "")
					data.clientAuthFlow = null;
				if(data.defaultLocale == "")
					data.defaultLocale = null;
				if(data.defaultRole == "")
					data.defaultRole = null;
				if(data.directGrantFlow == "")
					data.directGrantFlow = null;
				if(data.dockerAuthFlow == "")
					data.dockerAuthFlow = null;
				if(data.emailTheme == "")
					data.emailTheme = null;
				if(data.loginTheme == "")
					data.loginTheme = null;
				if(data.masterAdminClient == "")
					data.masterAdminClient = null;
				if(data.name == "")
					data.name = null;
				if(data.otpPolicyAlg == "")
					data.otpPolicyAlg = null;
				if(data.otpPolicyType == "")
					data.otpPolicyType = null;
				if(data.passwordPolicy == "")
					data.passwordPolicy = null;
				if(data.registrationFlow == "")
					data.registrationFlow = null;
				if(data.resetCredentialsFlow == "")
					data.resetCredentialsFlow = null;
				if(data.sslRequired == "")
					data.sslRequired = null;
				return data
			}
		},
		'resource-attribute': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "resourceId", name: "resource_id", editable: true, resizable: true, type: "text" },
			   	{ key: "value", name: "value", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.name == "")
					data.name = null;
				if(data.resourceId == "")
					data.resourceId = null;
				if(data.value == "")
					data.value = null;
				return data
			}
		},
		'resource-server-scope': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "displayName", name: "display_name", editable: true, resizable: true, type: "text" },
			   	{ key: "iconUri", name: "icon_uri", editable: true, resizable: true, type: "text" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "resourceServerId", name: "resource_server_id", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.displayName == "")
					data.displayName = null;
				if(data.iconUri == "")
					data.iconUri = null;
				if(data.name == "")
					data.name = null;
				if(data.resourceServerId == "")
					data.resourceServerId = null;
				return data
			}
		},
		'group-attribute': {
			columns: [
			   	{ key: "id", name: "id", editable: false, resizable: true},
			   	{ key: "groupId", name: "group_id", editable: true, resizable: true, type: "text" },
			   	{ key: "name", name: "name", editable: true, resizable: true, type: "text" },
			   	{ key: "value", name: "value", editable: true, resizable: true, type: "text" },
			],
			form2dto: (data) => {
				if(data.groupId == "")
					data.groupId = null;
				if(data.name == "")
					data.name = null;
				if(data.value == "")
					data.value = null;
				return data
			}
		},
}

export const buildEmptyObject = (entity) => {
	var columns = new Object();
	let fields = pojoMetadata[entity].columns
	for (let col in fields) {		
		if(fields[col].type == 'text'){
			columns[fields[col].key] = "";
		}else if(fields[col].type=='checkbox'){
			columns[fields[col].key] = false;
		}else if(fields[col].type=='datetime-local'){
			columns[fields[col].key] = "";
		}
		else if(fields[col].type=='autocomplete'){
			columns[fields[col].key] = null;
		}
	 }
	return columns
};

export const getEntityIdCol = (entity) => {
	return pojoMetadata[entity].columns[0].key;
}

export const getRowId = (entity, row) => {
	return row[getEntityIdCol(entity)];
}


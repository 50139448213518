import { DocumentInfo } from '../editors/DocumentsManagement';
import { UserInfo } from '../../../auto/js/users/UserInfo';

// Eventually, should return all users working on editor
export const getUsersWorkingOnDocument: (document: DocumentInfo) => Promise<UserInfo[]> = 
	(document) => new Promise(
		(resolve) => {
				let users = [
					{
						firstname: 'Ammar',
						lastname: 'Bouzwer',
						username: 'AmmarB',
						email: 'ammar.bouzwer@gmail.com',
						picture: '/avatar.png',
						roles: []
					},
					{
						firstname: 'Mimi',
						lastname: 'De Papa',
						username: 'Mamour',
						email: 'mimi.instagram@yahoo.fr',
						picture: '/avatar.png',
						roles: []
					}
				];
				resolve(users);
		}
	);


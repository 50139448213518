export class Talifoon {
    constructor() {
        this.listen = undefined;
    }

    pickUpThePhone( listen ) {
        this.listen = listen;
    }

    hangUp( ) {
        this.listen = undefined;
    }

    send( callback, answerMachine ) {
        if (this.listen)
            this.listen(callback);
        else
            answerMachine();
    }
}
import { rest } from "../services";

import { setupI18n } from "@lingui/core";

/* One singleton per app */
class I18ndb {

	static localeId;
	static i18n;
	static catalog;

	constructor() {
		this.catalog = {};
		this.i18n = setupI18n();
	}

	setLocale = (localeId) => {
		this.localeId = localeId;
		this.i18n.activate(localeId);
		this.catalog[localeId] = {};
	}

	loadCatalog = async (domainId) => {
		if (!domainId)
			domainId='-';
		if (!this.catalog[this.localeId][domainId])
			return rest.request(`/api/i18n/${this.localeId}/${domainId}`, "GET")
				.then(response => {
					let catalog = {};
					catalog[this.localeId] = {};
					catalog[this.localeId]['messages']=response;
					this.i18n.load(catalog);
					this.catalog[this.localeId][domainId] = true;
				});
		return Promise.resolve();
	}
	
	getCatalog = () => this.i18n._catalogs;
	
	_ = (msg) => this.i18n._({
		id: msg
	});
}

export const i18ndb = new I18ndb();
export function t(msg) { 
	let trans = i18ndb._(msg);
	if (trans!=msg)
		return trans;
	else {
		if (typeof msg === 'string' || msg instanceof String)
			return msg
		else
			//assume it's a string wrapped in an object
			return msg[0];
	}
};
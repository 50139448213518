import { DocumentInfo } from './DocumentsManagement';
import React from 'react';
import { OPEN_VIEW_EVENT } from '../../../auto/js/events/Gui';
import {v4 as uuidv4} from 'uuid';

interface EditorDescriptor {
	editorId: string,
	editorVersion: string
}

export const getFavoriteEditor: (mimeType: string) => Promise<EditorDescriptor> = 
	(mimeType) => new Promise(
		(resolve) => {
				let favoriteEditors: { [key: string]: EditorDescriptor } = {
					'tennex/dir': { editorId: 'Tennex/tennex-explorer', editorVersion: '1.0.0' },
					'tennex/erd': { editorId: 'Tennex/erdeditor', editorVersion: '1.0.0' },
					'tnx/config': { editorId: 'Tennex/tnx/config', editorVersion: '1.0.0' }
				};
				resolve(favoriteEditors[mimeType]);
		}
	);

interface EditorInstance extends EditorDescriptor {
	uuid: string
}

const Editor = (props: EditorInstance) => 
	(<div style={{height: 'calc(100% - 48px)'}}>
		<iframe id={props.uuid} src={'/g/' + props.editorId + '/' + props.editorVersion + '/#' + props.uuid} height='100%' width='100%' seamless frameBorder='0' sandbox="allow-forms allow-modals allow-scripts allow-same-origin">
		</iframe>
	</div>)

export const openDocument = (document: DocumentInfo) => {
	getFavoriteEditor(document.mimeType).then( (editor) => {
			if (editor) {
				let uuid = uuidv4();
				OPEN_VIEW_EVENT.publish({
					uuid,
					view: () => <Editor uuid={uuid} {...editor}/>,
					data: document
				});
			}
		}
	)
}
import { openDocument } from '../editors/Editors';

export const menu = () => {
	return {
		"bookmarks": {
			submenu: {
				other: {
					options: {
						explorer: { icon: "explorer", label: "Explorer", do: () => openDocument({ path: '/', mimeType: 'tennex/dir' }) },
					}
				}
			},
			label: "Bookmarks"
		}
	}
}
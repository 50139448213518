import {GUI_LANGUAGE_CHANGE_EVENT} from './events/Gui';

class AppContext {
    constructor() {
        if (!!AppContext.instance) {
            return AppContext.instance;
        }

        AppContext.instance = this;
        this.form = undefined;
		this.language = undefined;
		GUI_LANGUAGE_CHANGE_EVENT.subscribe((l) => this.setLanguage(l));
        return this;
    }

    openForm(f) {
        this.form = f;
    }

    closeForm(f) {
        this.form = undefined;
    }

	getCurrentForm() {
		return this.form;
	}
	
	getLanguage() {
		return this.language;
	}
	
	setLanguage = (l) => {
		this.language = l;
	}
}

export const appContext = new AppContext();

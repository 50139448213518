import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t, rest } from '../../../auto/js/services';
import toolbarStyles from './ToolBar.styl';
import { whoami } from '../../../auto/js/users/UserInfo';
import { UserAvatar } from '../../../auto/js/users/Avatars';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export function ToolBar(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar color="inherit" className={toolbarStyles.toolbar} >
        <img alt="logo.png" src="/logo.png" style={{maxWidth: '35px', marginRight: 'auto'}}/>
            <Button
            color="inherit"
            className={toolbarStyles.navbarBtn}
            onClick={() => {
               console.log('logging out');
               rest.oidcService.logout();
            }}
          >
		  {UserAvatar(whoami())}
          <FontAwesomeIcon icon="sign-out-alt" style={{ fontSize: '1.75em' }} title={t`Sign out`} />
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}

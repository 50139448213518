import React from 'react';
import AvatarEditor from 'react-avatar-editor'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';
import PhotoIcon from '@material-ui/icons/Photo';
import DeleteIcon from '@material-ui/icons/Delete';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import RotateRightIcon from '@material-ui/icons/RotateRight';

import { loadImageFile } from '../utils/load-image-file';

import { withStyles } from '@material-ui/core/styles';

import { ImageDropZone } from './ImageDropZone';

import { t } from "../services";

const styles = (theme) => ({
	root: {
	  margin: 0,
	  padding: theme.spacing(2),
	},
	closeButton: {
	  position: 'absolute',
	  right: theme.spacing(1),
	  top: theme.spacing(1),
	  color: theme.palette.grey[500],
	},
  });
  
  const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onCancel, ...other } = props;
	return (
	  <MuiDialogTitle disableTypography className={classes.root} {...other}>
		<Typography variant="h6">{children}</Typography>
		{onCancel ? (
		  <IconButton aria-label="close" className={classes.closeButton} onClick={onCancel}>
			<CloseIcon />
		  </IconButton>
		) : null}
	  </MuiDialogTitle>
	);
  });
  
  const DialogContent = withStyles((theme) => ({
	root: {
	  padding: theme.spacing(2),
	},
  }))(MuiDialogContent);
  
  const DialogActions = withStyles((theme) => ({
	root: {
	  margin: 0,
	  padding: theme.spacing(1),
	},
  }))(MuiDialogActions);

/**
	AvatarPreferences returns {x, y, scale, rotate, url} through onOk
 */
export class ImageEditor extends React.Component {
	
	constructor (props) {
		super(props);
		this.state = {
			url: props.image.url,
			isEmpty: props.image.isEmpty,
			position: { x: props.image.x, y: props.image.y },
			scale: props.image.scale,
			rotate: props.image.rotate,
			borderRadius: 100,
			width: 128,
			height: 128,
			avatarId: props.avatarId,
		}
	}

	handleNewImage = e => {
		this.setState({ image: e.target.files[0] })
		this.handleDrop(e.target.files[0])
	}

	handleOk = () => {
		const image = {
			x: this.state.position.x,
			y: this.state.position.y,
			rotate: this.state.rotate,
			scale: this.state.scale,
			url: this.state.url,
			isEmpty: this.state.isEmpty
		}
		this.props.onOk(image);
	}

	handleDelete = () => {
		this.props.onDelete.then(response => {
			this.setState({
				...this.state,
				url: response.url,
				position: { x: response.x, y: response.y },
				scale: response.scale,
				rotate: response.rotate,
				avatarId: response.avatarId,
			})
		});
		this.props.deleteField();
	}

	handleCancel = () => {
		this.props.onCancel();
	}

	handleScale = (e, newScale) => {
		this.setState({ scale: newScale })
	}

	rotateLeft = e => {
		e.preventDefault()

		this.setState({
			rotate: this.state.rotate - 90,
		})
	}

	rotateRight = e => {
		e.preventDefault()
		this.setState({
			rotate: this.state.rotate + 90,
		})
	}

	handleBorderRadius = e => {
		const borderRadius = parseInt(e.target.value)
		this.setState({ borderRadius })
	}

	handleXPosition = e => {
		const x = parseFloat(e.target.value)
		this.setState({ position: { ...this.state.position, x } })
	}

	handleYPosition = e => {
		const y = parseFloat(e.target.value)
		this.setState({ position: { ...this.state.position, y } })
	}

	handleWidth = e => {
		const width = parseInt(e.target.value)
		this.setState({ width })
	}

	handleHeight = e => {
		const height = parseInt(e.target.value)
		this.setState({ height })
	}

	logCallback(e) {
		console.log('callback', e)
	}

	setEditorRef = editor => {
		if (editor) this.editor = editor
	}

	handlePositionChange = position => {
		this.setState({ position })
	}

	handleDrop = acceptedFile => {
		loadImageFile(acceptedFile).then(img => {
			this.setState({...this.state, file: acceptedFile, url: img.src, isEmpty: false});
		})
	}

	render() {
		const {onCancel, open} = this.props
		return (
			<Dialog open={open} onClose={onCancel} aria-labelledby="form-dialog-title" maxWidth="800px">
				<DialogTitle id="customized-dialog-title" onClose={onCancel}>
					{t`Edit the image`}
				</DialogTitle>
				<DialogContent dividers>
					<Grid container spacing={1}>
						<Grid item m>
          					<ImageDropZone onDropCallback={this.handleDrop}/>
							<br />
							<div>
							<input accept="image/*" id="icon-button-file" type="file" onChange={this.handleNewImage} style={{display:'none'}} />
      						<label htmlFor="icon-button-file" style={{float:"right"}}>
        						<IconButton color="primary" aria-label="upload picture" component="span">
         							<PhotoIcon />
        						</IconButton>
     			 			</label>
							<IconButton color="primary" aria-label="delete picture" component="span" onClick={this.handleDelete}>
								<DeleteIcon />
							</IconButton>
							</div>
        				</Grid>
						<Grid item m>
							<AvatarEditor
								ref={this.setEditorRef}
								scale={this.state.scale}
								width={this.state.width}
								height={this.state.height}
								position={this.state.position}
								onPositionChange={this.handlePositionChange}
								rotate={parseFloat(this.state.rotate)}
								borderRadius={this.state.width / (100 / this.state.borderRadius)}
								onLoadFailure={this.logCallback.bind(this, 'onLoadFailed')}
								onLoadSuccess={this.logCallback.bind(this, 'onLoadSuccess')}
								onImageReady={this.logCallback.bind(this, 'onImageReady')}
								image={this.state.file ? this.state.file : this.props.image.url}
								className="editor-canvas"
							/>
							<br />
									<Slider 
										defaultValue={1}
										onChange={this.handleScale} 
										aria-labelledby="continuous-slider" 
										min={0.1}
										max={2}
										step={0.01} />
									<br />
									<div>
										<IconButton color="primary" aria-label="rotate-right" onClick={this.rotateRight}>
											<RotateRightIcon />
										</IconButton>
										<IconButton color="primary" aria-label="rotate-left" onClick={this.rotateLeft} style={{marginLeft:"30%"}}>
											<RotateLeftIcon />
										</IconButton>
									</div>
									<br />
									<br />
						</Grid>
					</Grid>
				</DialogContent>
        			<DialogActions>
         				<Button autoFocus onClick={this.handleCancel} color="primary">
            				{t`cancel`}
          				</Button>
						<Button autoFocus onClick={this.handleOk} color="primary">
            				{t`ok`}
          				</Button>
        			</DialogActions>
			</Dialog>
		)
	}
}